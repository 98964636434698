import React from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import Header from "../../components/Header";

export default () => (
  <div className="container" style={{ padding: "24px" }}>
    <Header /> <br /> <br />
    <h2>
      <strong>PRIVATLIV OG COOKIES</strong>
    </h2>
    <p>
      <strong>
        Data registreres ved kontakt eller n&aring;r ordre afgives
      </strong>
    </p>
    <p>
      Fastpack registrerer dine oplysninger, n&aring;r du tager kontakt til os,
      udfylder en formular eller bestiller et produkt. Den registrerede data
      anvendes til at kontakte dig, til nyhedsbrev (hvis &oslash;nsket), samt
      til udf&oslash;rsel, levering og betaling af din ordre.
    </p>
    <p>
      <strong>Hvilken data registreres?</strong>
    </p>
    <ul>
      <li>
        <p>Fornavn</p>
      </li>
      <li>
        <p>Efternavn</p>
      </li>
      <li>
        <p>Kontakt info</p>
      </li>
      <li>
        <p>Virksomhedsinfo</p>
      </li>
      <li>
        <p>Info relateret til en ordre</p>
      </li>
    </ul>
    <p>
      <strong>Hvad sker der med mine data?</strong>
    </p>
    <p>
      De data, vi registrerer, videregives ikke og s&aelig;lges ikke til
      tredjeparter.
    </p>
    <p>
      <strong>Cookies og privatlivspolitik</strong>
    </p>
    <p>
      Vi anvender cookies til at registrere dine pr&aelig;ferencer under dit
      bes&oslash;g p&aring; vores website, s&aring; vi kan give dig den bedst
      mulige brugeroplevelse.
    </p>
    <p>
      <strong>Hvad er cookies?</strong>
    </p>
    <p>
      Cookies er sm&aring; filer med information som lagres i din enhed,
      n&aring;r du bes&oslash;ger et websted.
    </p>
    <p>Vi bruger f&oslash;lgende typer cookies:</p>
    <p>
      <strong>Sessionscookies:</strong>
    </p>
    <p>
      Midlertidige cookies der forsvinder, n&aring;r du du lukker din browser.
      Disse er n&oslash;dvendige for at de grundl&aelig;ggende funktioner
      p&aring; hjemmesiden kan fungere.
    </p>
    <p>
      <strong>Blivende cookies:</strong>
    </p>
    <p>
      Gemmes p&aring; din computer i l&aelig;ngere tid, til du sletter dem eller
      de udg&aring;r. Vi bruger dem for at lette f.eks. login og udfyldning af
      formularer.
    </p>
    <p>
      <strong>Analytiske cookies:</strong>
    </p>
    <p>
      Tredjeparts Cookies benyttes til markedsf&oslash;ring og udvikling af
      services. De giver information om dit brug af webstedet, der g&oslash;r os
      i stand til at forbedre din brugeroplevelse og tilpasse vores service,
      tilbud og markedsf&oslash;ringstiltag.
    </p>
    <p>
      <strong>Sletning af cookies</strong>
    </p>
    <p>
      Du kan altid slette eller sl&aring; cookies fra via
      sikkerhedsindstillingerne i din webbrowser.
    </p>
  </div>
);
